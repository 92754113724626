export const gridItems = [
  {
    id: 1,
    title: "Innovative tech solutions",
    description: "Powered by",
    className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
    imgClassName: "absolute md:w-full w-60 h-[20rem] opacity-50",
    titleClassName: "justify-end",
    img: "grid.webp",
    spareImg: "",
  },
  {
    id: 2,
    title: "Flexible with time zone communications",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "",
    spareImg: "",
  },
  {
    id: 3,
    title: "",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "Minimalist Modern Design",
    description: "UX / UI",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "/grid.webp",
    spareImg: "/b4.webp",
  },

  {
    id: 5,
    title: "Best Coding Practices",
    description: "SEO friendly, fast, and responsive",
    className: "md:col-span-3 md:row-span-2",
    imgClassName: "absolute right-0 bottom-0 md:w-96 w-36",
    titleClassName: "justify-center md:justify-start lg:justify-center",
    img: "/b5.webp",
    spareImg: "/grid.webp",
  },
  {
    id: 6,
    title: "Do you want to start a project together?",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-60 text-center",
    img: "",
    spareImg: "",
  },
];

export const projects = [
  {
    id: 1,
    title: "Blvck Sheep ",
    des: "Landing page for Blvck Sheep Shuttle services, modern design and animations.",
    img: "/blvcksheep.webp",
    iconLists: ["/re.svg", "/tail.svg", "/fm.svg"],
    link: "https://www.blvck-sheep.com/",
  },
  {
    id: 2,
    title: "Virtus Bookstore",
    des: "Online bookstore with a payment gateway, user authentication, and admin panel.",
    img: "/virtus.webp",
    iconLists: ["/re.svg", "/tail.svg", "/mongo.webp", "/node.webp"],
    link: "https://www.virgiliocordon.com/",
  },
  {
    id: 3,
    title: "Brainwave",
    des: "Modern UI/UX website using for an AI startup, minimalistic design and animations.",
    img: "/brainwave.webp",
    iconLists: ["/re.svg", "/tail.svg", "/fm.svg"],
    link: "https://brainwave-navy.vercel.app/",
  },
  {
    id: 4,
    title: "Lens Loop",
    des: "A social media application to share photos, like and save other user photos.",
    img: "/lensloop.jpg",
    iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/app.svg"],
    link: "https://lens-loop-beta.vercel.app/",
  },
  {
    id: 5,
    title: "Develop",
    des: "Marketing agency landing page with beautiful animations, dark mode and modern design.",
    img: "/develop.webp",
    iconLists: ["/css.webp", "/html.webp", "/js.webp"],
    link: "https://develop-marketing.vercel.app/",
  },
  {
    id: 6,
    title: "Gamer",
    des: "Gaming website with information about games, reviews, and blog posts.",
    img: "/gamer.webp",
    iconLists: ["/css.webp", "/html.webp", "/js.webp"],
    link: "https://gamer-blog-six.vercel.app/",
  },
];

export const testimonials = [
  {
    quote:
      "Collaborating with Byron and his team was an absolute pleasure. His professionalism, promptness, and dedication to delivering exceptional results were evident throughout our project.",
    name: "Virgilio Cordon",
    title: "CEO of Virtus Institute",
  },
  {
    quote:
      "Working with Inari Agency was a great experience. They helped me with our bakery's social media marketing and we saw a significant increase in our online presence and sales. I recommend their services to anyone looking to grow their business.",
    name: "Michelle Quiej",
    title: "Manager of Mon Cheri",
  },
  {
    quote:
      "Inari Agency's web development services are top-notch. They helped us build a custom web site for our Shuttle business, they are also helping us with our SEO and Google Ads campaigns. We are very happy with the results and the quality of their work.",
    name: "Federico Lopez",
    title: "Marketing Manager of Blvck Sheep",
  },
];

export const companies = [
  {
    id: 1,
    name: "Cloudinary",
    img: "/cloud.svg",
    nameImg: "/cloudName.svg",
  },
  {
    id: 2,
    name: "appwrite",
    img: "/app.svg",
    nameImg: "/appName.svg",
  },
  {
    id: 3,
    name: "HOSTINGER",
    img: "/host.svg",
    nameImg: "/hostName.svg",
  },
  {
    id: 4,
    name: "Stream",
    img: "/s.svg",
    nameImg: "/streamName.svg",
  },
  {
    id: 5,
    name: "docker.",
    img: "/dock.svg",
    nameImg: "/dockerName.svg",
  },
];

export const services = [
  {
    id: 1,
    title: "Custom Web Application Development",
    desc: "We build powerful and efficient web applications from start to finish. Our solutions are tailored to meet your unique business needs, ensuring a seamless user experience and robust performance.",
    className: "md:col-span-2",
    thumbnail: "/exp1.svg",
  },
  {
    id: 2,
    title: "Minimalistic Web Design",
    desc: "Our designs prioritize simplicity and elegance, focusing on creating user-friendly and responsive websites. We ensure a modern, clean look that enhances user experience and makes your website stand out.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp3.webp",
  },
  {
    id: 3,
    title: "Social Media Management",
    desc: "Boost your online presence with our Meta-certified social media services. We create optimized campaigns to increase user engagement, brand awareness, and drive more traffic to your business.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/exp2.webp",
  },
  {
    id: 4,
    title: "Search Engine Optimization",
    desc: "Improve your website's visibility with our expert SEO services. We use advanced strategies to help your site rank higher on search engines, attracting more organic traffic and expanding your reach.",
    className: "md:col-span-2",
    thumbnail: "/exp4.webp",
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
  },
  {
    id: 2,
    img: "/twit.svg",
  },
  {
    id: 3,
    img: "/link.svg",
  },
];

export const pricing = [
  {
    id: "0",
    title: "Social Marketing",
    description: "Meta advertisment plans starting at:",
    price: "100",
    features: [
      "Meta certified marketers to optimize your campaigns",
      "Return on investment tracking and analytics",
      "Dedicated account manager to help with your social media",
    ],
  },
  {
    id: "1",
    title: "Web Development",
    description:
      "Modern UX/UI design and web application development starting at:",
    price: "200",
    features: [
      "Modern libraries and frameworks for fast and responsive web apps",
      "SEO optimization and best practices for easier search engine indexing",
      "Custom web design to drive conversions and user engagement",
    ],
  },
  {
    id: "2",
    title: "SEO Optimization",
    description:
      "SEO services to increase your website's visibility starting at:",
    price: "350",
    features: [
      "Improve site speed and performance for better user experience",
      "Optimize your code so search engines find, crawl, and index your site",
      "Best practices to improve title tags, meta descriptions, and more",
    ],
  },
];
